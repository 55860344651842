import type { ITextStyle } from 'pixi.js';

const font = 'NotoSans-SemiCondensedBold';

const valueDefaultStyles: Partial<ITextStyle> = {
  align: 'center',
  fontFamily: font,
  fontSize: 200,
  lineHeight: 200,
  fontWeight: 'bold',
  whiteSpace: 'normal',
  padding: 30,
  trim: true,
  stroke: '#000000',
  strokeThickness: 20,
  lineJoin: 'round',
};

const defaultText1Style: Partial<ITextStyle> = {
  align: 'left',
  fontFamily: font,
  fontSize: 60,
  lineHeight: 100,
  fontWeight: 'bold',
  whiteSpace: 'normal',
  padding: 25,
  trim: true,
  stroke: '#000000',
  strokeThickness: 10,
  lineJoin: 'round',
};

const defaultText2Style: Partial<ITextStyle> = {
  align: 'center',
  fontFamily: font,
  fontSize: 40,
  lineHeight: 70,
  fontWeight: 'bold',
  whiteSpace: 'normal',
  padding: 25,
  trim: true,
  stroke: '#000000',
  strokeThickness: 10,
  lineJoin: 'round',
};

export const textPress: Partial<ITextStyle> = {
  align: 'center',
  fill: '#ffffff',
  fontFamily: 'NotoSans-SemiCondensedBold',
  fontSize: 32,
  stroke: '#000000',
  strokeThickness: 4,
  lineJoin: 'round',
};

export const masterTextValueStyle: Partial<ITextStyle> = {
  ...valueDefaultStyles,
  fill: ['#FFFACE', '#FFED45', '#FFA901'],
  fillGradientStops: [0, 0.5, 0.75],
};

export const masterText1Style: Partial<ITextStyle> = {
  ...defaultText1Style,
  fill: ['#FFFACE', '#FFED45', '#FFA901'],
  fillGradientStops: [0, 0.5, 0.75],
};

export const masterText2Style: Partial<ITextStyle> = {
  ...defaultText2Style,
  fill: ['#FFFACE', '#FFED45', '#FFA901'],
  fillGradientStops: [0, 0.5, 0.75],
};

export const freeTextValueStyle: Partial<ITextStyle> = {
  ...valueDefaultStyles,
  fill: ['#FFFFFF', '#72F7FF', '#01A4FF'],
  fillGradientStops: [0, 0.5, 0.75],
};

export const freeText1Style: Partial<ITextStyle> = {
  ...defaultText1Style,
  fill: ['#FFFFFF', '#72F7FF', '#01A4FF'],
  fillGradientStops: [0, 0.5, 0.75],
};

export const freeText2Style: Partial<ITextStyle> = {
  ...defaultText2Style,
  fill: ['#FFFFFF', '#72F7FF', '#01A4FF'],
  fillGradientStops: [0, 0.5, 0.75],
};

export const rarityText1Style: Partial<ITextStyle> = {
  ...defaultText1Style,
  lineHeight: 70,
  fill: ['#FFFDC6', '#FCFF7D', '#98DF00'],
  fillGradientStops: [0, 0.5, 0.75],
};

export const rarityText2Style: Partial<ITextStyle> = {
  ...defaultText2Style,
  fill: ['#FFFDC6', '#FCFF7D', '#98DF00'],
  fillGradientStops: [0, 0.5, 0.75],
};

export const multiplierTextValueStyle: Partial<ITextStyle> = {
  ...valueDefaultStyles,
  fill: ['#FFF4F7', '#FFC3CE', '#FF6388'],
  fillGradientStops: [0, 0.5, 0.75],
};

export const multiplierText1Style: Partial<ITextStyle> = {
  ...defaultText1Style,
  fill: ['#FFF4F7', '#FFC3CE', '#FF6388'],
  fillGradientStops: [0, 0.5, 0.75],
};
