import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import { TFunction, useTranslation } from 'react-i18next';

import { Popup } from '@phoenix7dev/shared-components/';
import { cashierUrlAvailable, goToCashier } from '@phoenix7dev/utils-fe';

import { GameMode } from '../../global.d';
import { setIsResourceLoaded, setIsRevokeThrowingError, setProgress, setStressful } from '../../gql/cache';
import type { IStressful } from '../../gql/d';
import { stressfulGql } from '../../gql/query';
import { Logic } from '../../logic';
import { queryParams } from '../../utils';

import styles from './stressful.module.scss';

const defaultStressful: IStressful = {
  show: false,
  type: 'none',
  message: '',
};

const getMessageTypeError = (t: TFunction<'translation'>, message: string) => {
  if (message) {
    return message;
  }
  return t('errors.UNKNOWN.UNKNOWN');
};

const Stressful: React.FC = () => {
  const { data } = useQuery<{ stressful: IStressful }>(stressfulGql);
  const { t } = useTranslation();
  const resourceLoaded = useReactiveVar(setIsResourceLoaded);
  const { wasLoaded, status } = useReactiveVar(setProgress);
  const btn = React.useMemo(() => {
    if (data?.stressful.type === 'balance') {
      return (
        (cashierUrlAvailable(queryParams) || true) && (
          <button className={`${styles['btn']} btn`} onClick={() => goToCashier(queryParams)}>
            {t('cashier')}
          </button>
        )
      );
    }
    return null;
  }, [data?.stressful, t]);

  if (!data) {
    return null;
  }

  const { message, type, title } = data.stressful;

  const showCloseButton =
    (wasLoaded || status === 100) &&
    (Logic.the.controller.gameMode === GameMode.BASE_GAME ||
      Logic.the.controller.gameMode === GameMode.BUY_FEATURE ||
      Logic.the.controller.gameMode === GameMode.FREE_ROUND_BONUS) &&
    type !== 'blocking';

  return (
    <div className={data.stressful.show ? styles['backdrop'] : ''}>
      <Popup
        id="stressful"
        className={`${resourceLoaded ? '' : 'loading'} popup`}
        showNow={data.stressful.show}
        setShowNow={() => null}
      >
        <div className={styles['stressful']}>
          <div className={styles['center']}>
            {title && <p className={styles['title']}>{title}</p>}
            <p className={styles['title']}>{getMessageTypeError(t, message)}</p>
            {btn}

            {showCloseButton && (
              <button
                className={`${styles['btn']} btn`}
                onClick={() => {
                  setStressful(defaultStressful);
                  setIsRevokeThrowingError(false);
                  if (data.stressful.callback) {
                    data.stressful.callback();
                  }
                }}
              >
                {t('close')}
              </button>
            )}
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Stressful;
